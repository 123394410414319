const $ = require('jquery');

global.$ = global.jQuery = $;

require('popper.js/dist/popper');
require('bootstrap/dist/js/bootstrap');
require('./plugins/js/02.jquery.easing');
require('jquery-breakpoints');
require('isotope-layout/dist/isotope.pkgd.min');
require('owl-carousel-2/owl.carousel.min')
require('./owl.fix');
require('fitvids');
require('vide');
require('scrolly');
require('magnific-popup');
require('js-cookie');
require('jquery-form');
require('scrollify');
require('tweetie/dist/tweetie.min');
require('./plugins/js/20.jquery.flickrfeed');
require('./plugins/js/08.jquery.fitvids');
require('./plugins/js/11.jquery.scrolly');
require('./plugins/js/jquery.lazy.min');

require('jquery-countto');
require('morphext/dist/morphext.min');
require('spectragram');
require('easy-pie-chart');
//require('jquery.countdown');
require('./plugins/js/25.jquery.countdown');
require('infinite-scroll');
require('./plugins/js/27.jquery.hover3d');
require('bootstrap-switch/dist/js/bootstrap-switch.min');
require('sticky-sidebar');
require('./plugins/js/17.jquery.bootstrapnotify');
require('jquery-lazy');
require('theia-sticky-sidebar');
require('waypoints/src/waypoint');
require('waypoints/src/group');
require('waypoints/src/context');
require('waypoints/src/adapters/jquery');
var Cookies = require('js-cookie');
global.Cookies = Cookies;
require('./functions.js');
require('./plugins/js/06.jquery.isotope');

require('../scss/app.scss');
require('animate.css/animate.min.css');
require('owl-carousel-2/assets/owl.carousel.css');
require('@fortawesome/fontawesome-free/scss/brands.scss');
require('@fortawesome/fontawesome-free/scss/regular.scss');
require('@fortawesome/fontawesome-free/scss/solid.scss');
require('@fortawesome/fontawesome-free/scss/fontawesome.scss');
require('../css/inspiro.css');
require('bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css');
require('ion-rangeslider/css/ion.rangeSlider.css');
require('../scss/style.scss');

require('flickity/dist/flickity.pkgd.min');
require('flickity/dist/flickity.min.css');

/* require('./plugins/js/jquery.flickity'); */


$(document).ready(function () {
    console.log('OIMMEI START SCRIPT');
});
